import { links } from '../components/submission/common';
import { inSellerDashboardExperiment } from './experiment';
import { CookieStore } from '../services/cookieStore';

const { REACT_APP_SITE_URL, REACT_APP_SITE_URL_DEV } = process.env;

export const addUvidParam = (url: string): string => {
  const uvid = CookieStore.getUvid();
  if (!uvid) return url;

  const urlObj = new URL(url); // eslint-disable-line
  urlObj.searchParams.append('uvid', uvid);
  return urlObj.toString();
};

export const redirectTo = (url: string) => {
  window.location.replace(addUvidParam(url));
};

export const directToItemTypePage = () => {
  window.location.href = links.submissionFlowRepositioning;
};

export const directToHomePage = () =>
  redirectTo(REACT_APP_SITE_URL_DEV || REACT_APP_SITE_URL || '');

export const redirectToNewItem = (itemId: string) => {
  const sellerDashboardExperiment = inSellerDashboardExperiment();
  console.log('sellerDashboardExperiment', sellerDashboardExperiment);
  const newItemUrl = sellerDashboardExperiment
    ? `${process.env.REACT_APP_SELLER_DASHBOARD_URL}/shipping?itemId=${itemId}&survey=true` || ''
    : `${process.env.REACT_APP_APP_URL}/item/${itemId}/skip_photo_upload`;
  redirectTo(newItemUrl);
};
